import React from 'react';
import Typography from '@mui/material/Typography';

const JudicialDetailColumns = [
  {
    field: 'identification',
    headerName: 'ID',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.identification}</Typography>
    ),
  },
  {
    field: 'type',
    headerName: 'Tipo',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.type}</Typography>
    ),
  },
  {
    field: 'creditor',
    headerName: 'Creditor',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.creditor}</Typography>
    ),
  },
  {
    field: 'clasification',
    headerName: 'Clas.',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.clasification}</Typography>
    ),
  },
  {
    field: 'process',
    headerName: 'Proceso',
    type: 'string',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    flex: 0.2,
    renderCell: ({ row }) => (
      <Typography>{row.process}</Typography>
    ),
  },
];

export default JudicialDetailColumns;
